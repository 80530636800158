import React from 'react'

import { Link } from 'gatsby'

const TagNav = ({ data, navClass }) => (
    <>
        {data.map((navItem, i) => {
            return <Link className={navClass} to={'/tag/' + navItem.node.slug} key={i}>{navItem.node.name}</Link>
        })}
    </>
)

TagNav.defaultProps = {
    navClass: `tag-nav-item`,
}

export default TagNav