import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const BusinessTag = ({tag , xPostSection}) => {
  
  const dataBusiness = useStaticQuery(graphql`
  query btagPostQuery {
      allGhostPost(
          sort: { order: DESC, fields: [published_at] },
          limit: 4,
          filter: {tags: {elemMatch: {slug: {eq: "business"}}}}
      ) {
        edges {
          node {
            ...GhostPostFields
          }
        }
      }
  }
`)

const pdata = dataBusiness.allGhostPost.edges
  
  if (xPostSection == 1 ) {
  return (
    <div className="col-lg-4">
      <div className="column-post-section post-group">
      <div className="post-group-title">
            <span>{tag}</span>
        </div>
                    
                      {pdata.map((post,i)=> {
                        return (
                            <>
                            {i == 0 && (
                            <div className="large-post" key={i}>
                                <a href={post.node.slug} title={post.node.title}>
                                    <div className="featured-image-wrap">
                                        
                                        {post.node.feature_image &&
                                        <div className="featured-image" 
                                          style={{backgroundImage: `url(${post.node.feature_image})` ,}}>
                                        </div>}
                                        
                                        <div className="overlay">
                                            <h2 className="title h3">{post.node.title}</h2>
                                            <div className="date" title={post.node.published_at}>
                                              { new Intl.DateTimeFormat('en-US', {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                              }).format(new Date(post.node.published_at))}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                              </div>
                            )}
                            {i > 0 && ( 
                              <div className="small-post clearfix" key={i}>
                              <a href={post.node.slug}>
                                  <div className="featured-image-wrap">
                                  {post.node.feature_image &&
                                                                <div className="featured-image" 
                                                                  style={{backgroundImage: `url(${post.node.feature_image})` ,}}>
                                                                </div>}
                                  </div>
                              </a>
      
                              <div className="content-wrap">
                                  <h2 className="title h4"><a href={post.node.slug}>{post.node.title}</a></h2>
                                  <div className="date" title={post.node.published_at}>
                                              { new Intl.DateTimeFormat('en-US', {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                              }).format(new Date(post.node.published_at))}
                                  </div>
                              </div>
                              </div>
                            )}
                        
                        </>

                        )
                      })}
                    
      </div>
    </div>
  )
 } else if (xPostSection == 0 ) {
  return (
    <div className="col-lg-12">
    <div className="row-post-section post-group">
        <div className="post-group-title">
            <span>{tag}</span>
        </div>
        <div className="row">
        {pdata.map((post,i)=> {
          return (
            <>
            <div className="col-lg-3 col-md-6 post-wrap"  key={i} >
                
                <a href={post.node.slug}>
                <div className="featured-image-wrap">
                                  {post.node.feature_image &&
                                                                <div className="featured-image" 
                                                                  style={{backgroundImage: `url(${post.node.feature_image})` ,}}>
                                                                </div>}
                                  </div>
                </a>
                
                <h2 className="title h4"><a href={post.node.slug}>{post.node.title}</a></h2>
                <div className="date" title={post.node.published_at}>
                                              { new Intl.DateTimeFormat('en-US', {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                              }).format(new Date(post.node.published_at))}
                                  </div>
                
            </div>
            </>
          )
        })} 
        </div>
    </div>
</div>
  )
 }
}

export default BusinessTag