import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import { TagNav } from '.'
import config from '../../utils/siteConfig'

// Styles
import '../../styles/google-font.css'
import '../../styles/app.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node
    const tags = data.allGhostTag.edges
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

      const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
      }

      const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...state,
          }),
        })
          .then(() => navigate(form.getAttribute('action')))
          .catch((error) => alert(error))
      }


    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>


            <div className="viewport">
                <div className="viewport-top">
                    <header className="site-head" >
                        <div className="container">
                            <div className="site-mast">
                                <div className="site-mast-left">
                                    <Link to="/">
                                        {site.logo ?
                                            <img className="site-logo" src={site.logo} alt={site.title} />
                                            : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
                                        }
                                    </Link>
                                </div>
                                <div className="site-banner-desc">{site.description}</div>
                            </div>

                            <nav className="site-nav">

                                <div className="site-nav-left">
                                    <Navigation data={site.navigation} navClass="site-nav-item" />
                                </div>
                                <span className="search-button d-inline-flex search-open">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z"></path></svg>
                                </span>
                            </nav>
                        </div>
                    </header>

                    <main className="site-main">
                        {children}
                    </main>
                </div>

                <div className="viewport-bottom">
                    <footer className="site-foot">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                <div className="widget">
                                    <h4 className="widget-title">{site.title}</h4>
                                    <div className="content">
                                        <p>Cape Cod's most trusted? source for local news, straight from the horse's mouth, gross!</p>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-5">

                                    <div className="widget">
                                        <h4 className="widget-title">Navigation</h4>
                                        <div className="site-foot-nav-right navigation">
                                            <Navigation data={site.navigation} navClass="site-foot-nav-item" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="widget">
                                        <h4 className="widget-title">Subscribe</h4>
                                        <div className="content subscribe-widget">
                                            <p>Get all the latest posts delivered straight to your inbox.</p>
                                            <form className="members-form" data-members-form="signin" method="POST" action="https://getform.io/f/9fca7141-2080-4d4b-9827-581ea6fd21a5" name="form-name">
                                                <div className="input-group pb-3">
                                                    <input data-members-email type="email" className="form-control" placeholder="Your email address" name="email" required/>
                                                    <input type="hidden" name="name"/>
                                                    <input type="hidden" name="message"/>
                                                    <div className="input-group-append">
                                                        <button className="btn btn-secondary" type="submit"><span className="loading-spinner"></span>Subscribe</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                Copyright  © 2020 {site.title} &mdash; All right Reserved.
                            </div>
                            <div className="custom-links">
                            </div>
                        </div>
                    </footer>
                </div>
            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                allGhostTag {
                    edges {
                      node {
                        ...GhostTagFields
                      }
                    }
                  }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
