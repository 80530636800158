import React from "react"
import { Link } from 'gatsby'

const LatestNews = ({ data, no }) => (
    <>
    {data.map((post, i) => {
    
    if (no == 0 && i == 0 ) {
                    return (
                    <div className="large-post clearfix" key={i}>
                        
                        <a href={'/' + post.node.slug +'/'}>
                            <div className="featured-image-wrap">
                              {post.node.feature_image &&
                                        <div className="featured-image" 
                                          style={{backgroundImage: `url(${post.node.feature_image})` ,}}>
                                        </div>}
                            </div>
                        </a>
                        
                        <div className="content-wrap">
                            <h2 className="title h4"><a href={'/' + post.node.slug +'/'}>{post.node.title}</a></h2>
                            <div className="date" title={post.node.published_at}>
                            { new Intl.DateTimeFormat('en-US', {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                              }).format(new Date(post.node.published_at))}
                                            </div>
                            <div className="excerpt">{post.node.excerpt }</div>
                        </div>
                    </div>)
      } else {

        if (no == 1 && i > 0 ) {
          return (
            <>
            <div className="col-md-6 small-post" key={i}>
                          <a href={'/' + post.node.slug +'/'}>
                              <div className="featured-image-wrap">
                                {post.node.feature_image &&
                                          <div className="featured-image" 
                                            style={{backgroundImage: `url(${post.node.feature_image})` ,}}>
                                          </div>}
                              </div>
                          </a>
                          <div className="content-wrap">
                                  <h2 className="title h5"><a href={'/' + post.node.slug +'/'}>{post.node.title}</a></h2>
                                  <div className="date" title={post.node.published_at}>
                                    { new Intl.DateTimeFormat('en-US', {
                                                  year: "numeric",
                                                  month: "long",
                                                  day: "2-digit"
                                                }).format(new Date(post.node.published_at))}
                                              </div>
                          </div>
              </div>
            </>
          )
        }

      }

    })}
    </>                
)

export default LatestNews