import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby'

const FeaturedPosts = () => {
  const data = useStaticQuery(graphql`
    query fPostQuery {
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            limit: 5,
            filter: {featured: {eq: true}}
        ) {
          edges {
            node {
              ...GhostPostFields
            }
          }
        }
    }
  `)
  const pdata = data.allGhostPost.edges
  return (
    <div className="featured-posts-section">
                <div className="container">
                    <div className="row clearfix">
                    
                      {pdata.map((post,i)=> {
                        return (
                            <>
                            {i == 0 && (
                            <div className="col-lg-6 large-post post-wrap post" key={i}>
                                <a href={post.node.slug} title={post.node.title}>
                                    <div className="featured-image-wrap">
                                        
                                        {post.node.feature_image &&
                                        <div className="featured-image" 
                                          style={{backgroundImage: `url(${post.node.feature_image})` ,}}>
                                        </div>}
                                        
                                        <div className="overlay">
                                            <h2 className="title h3">{post.node.title}</h2>
                                            <div className="date" title={post.node.published_at}>
                                              { new Intl.DateTimeFormat('en-US', {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                              }).format(new Date(post.node.published_at))}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div className="excerpt">
                                    {post.node.excerpt}
                                </div>
                              </div>
                            )}
                            {i > 0 && ( 
                              <div className="col-lg-6 small-post post-wrap post" key={i}>
                              <a href={post.node.slug}>
                                  <div className="featured-image-wrap">
                                  {post.node.feature_image &&
                                                                <div className="featured-image" 
                                                                  style={{backgroundImage: `url(${post.node.feature_image})` ,}}>
                                                                </div>}
                                  </div>
                              </a>
      
                              <div className="content-wrap">
                                  <div className="tag">
                                    <Link className="fpost-tag-nav-item" to={'/tag/' + post.node.tags[0].slug} key={i}>{post.node.tags[0].name}</Link>
                                  </div>
                                  <h2 className="title h4"><a href={post.node.slug}>{post.node.title}</a></h2>
                                  <div className="date" title={post.node.published_at}>
                                              { new Intl.DateTimeFormat('en-US', {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                              }).format(new Date(post.node.published_at))}
                                  </div>
                              </div>
                              </div>
                            )}
                        
                        </>

                        )
                      })}
                    </div>
                </div>
            </div>
  )
}

export default FeaturedPosts